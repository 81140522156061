.profile-data span,
.edit-data span,
.change-password span {
    font-size: 20px;
    color: #001bbe;
}

.profile-data .row,
.edit-data .row,
.change-password .row {
    margin-top: 10px;
}

.profile {
    input.input {
        width: 100%;
        max-width: none;
    }
}

.profile-data .col-md-3,
.edit-data .col-md-3,
.change-password .col-md-3 {
    font-weight: bold;
}
.profile-data label,
.edit-data label,
.change-password label {
    font-weight: bold;
}

.change-password input[type="submit"],
.edit-data input[type="submit"] {
    margin-top: 10px;
    margin-left: 15px;
    font-size: 16px;
    padding: 12px 16px;
    background-color: #009fd6;
    color: white;
    border: none;
}
.change-password input[type="submit"]:hover,
.edit-data input[type="submit"]:hover {
    background-color: #0085c3;
}
/* Style the tab */
.tab {
    float: right;
    width: 20%;
    height: 300px;
    margin-top: 50px;
}

.tab h3 {
    text-align: center;
    text-transform: uppercase;
    color: #009fd6;
    background-color: #eefbff;
    padding: 10px;
    margin: 0;
}

/* Style the buttons inside the tab */
.tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 12px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
}

.tab button img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

#main > div.main-content > div.tab > button:nth-child(4) > a {
    position: absolute;
    width: 270px;
    height: 41px;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #eefbff;
}

/* Create an active/current "tab button" class */
.tab button.active {
    background-color: #009fd6;
    /*background-color: #ccc;*/
}

/* Style the tab content */
.tabcontent {
    float: left;
    padding: 0px 12px;
    width: 70%;
    border-left: none;
    height: 300px;
}
