.header {
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 9999999;
    &__logo {
        padding-left: 25px;
        padding-top: 5px;
        max-width: 320px;
        width: 25%;
        float: left;
        background-color: #fff;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-top: 60px solid #009fd6;
            border-left: 75px solid transparent;
            right: 0;
            top: 0;
        }
        img {
            display: inline-block;
            padding-right: 20px;
        }
        a {
            span {
                font-size: 25px;
                font-family: "Roboto", sans-serif;
                font-weight: 700;
                color: #000;
                text-transform: uppercase;
                display: inline-block;
                vertical-align: top;
                line-height: 55px;
            }
        }
    }
    &__content {
        display: block;
        width: 100%;
        height: 60px;
        background-color: #009fd6;
    }
    form {
        height: 100%;
    }
    &__search {
        display: inline-block;
        height: 100%;
    }
    .search {
        margin-left: 40px;
        margin-top: 20px;
        height: 27px;
        max-width: 150px;
        border: none;
        border-radius: 4px;
        color: #8b8b8b;
        font-family: "PTSans", sans-serif;
        font-weight: 400;
        font-size: 16px;
        padding: 0 10px;
    }
    .btn-search {
        overflow: hidden;
        height: 0;
        width: 0;
        padding: 0;
        border: none;
    }
    &__login {
        float: right;
        cursor: pointer;
        position: relative;
        &:hover,
        &:focus,
        &:active{
            background-color: #0085c3;
            text-decoration: none;
        }
        .log-in {
            background-color: #0085c3;
            text-decoration: none;
        }
        .userName {
            font-size: 17px;
            font-family: "PTSans", sans-serif;
            font-weight: 400;
            color: #f6f6f6;
            list-style: none;
            height: 60px;
            line-height: 60px;
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding: 0 60px 0 0;
            width: auto;
            min-width: 140px;
            text-align: center;
            z-index: 3;
        }
        span::after {
            content: '';
            height: 8px;
            width: 15px;
            right: 35px;
            position: absolute;
            background-image: url('../img/images/dropdown.png');
            top: 50%;
            margin-top: -3px;
        }
    }
    &__avatar {
        position: relative;
        padding-top: 8px;
        padding-right: 8px;
        display: inline-block;
        margin-left: 15px;
        z-index: 3;
    }
    .dropMenu {
        display: none;
        transition: all 0.5s ease-in;
    }
    &__drop-list {
        vertical-align: top;
        background-color: #0085c3;
        padding: 75px 0 15px 0;
        width: 100%;
        position: absolute;
        height: auto;
        left: 0;
        top: 0;
        z-index: 2;
        &::before {
            content: "";
            position: absolute;
            width: 80%;
            height: 1px;
            background-color: #f4f0f0;
            top: 60px;
            left: 20px;
        }
        li {
            list-style: none;
            a {
                font-size: 17px;
                color: #fff;
                display: block;
                padding-left: 45px;
                line-height: 28px;
                text-decoration: none;
                &.login__my-account::before {
                    content: "";
                    position: absolute;
                    width: 14px;
                    height: 19px;
                    background-image: url('../img/images/login__my-account.png');
                    left: 15px;
                    top: 78px;
                }
            }
            a#logOut::before {
                content: "";
                position: absolute;
                width: 17px;
                height: 19px;
                background-image: url('../img/images/login__out.png');
                left: 15px;
                top: 108px;
            }
        }
    }

}
/*Header*/

$toggleColor: #c7c7c7;
$menusize: 10px;


.site-logo {
    width: 127px;
}

.top-menu {
    ul {
        list-style: none;
    }
}

.top-menu {
    list-style: none;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    li {
        padding: 0 5px;
    }
    a {
        font-size: 14px;
        text-decoration: none;
    }
}

.menu-toggle {
    font-size: 16px;
    cursor: pointer;
    right: 0;
    top: 28px;
    position: absolute;
    z-index: 4;
    width: 3.813em;
    height: 2.625em;
    span {
        display: block;
        font-size: $menusize;
        position: absolute;
        top: 1.063em;
        width: 3.813em;
        height: 0.500em;
        background: #c7c7c7;
        border-radius: 5px;
        transition: background 0s 0.3s;
        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 0.500em;
            background: $toggleColor;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            transition-duration: 0.3s, 0.3s;
            -webkit-transition-duration: 0.3s, 0.3s;
            transition-delay: 0.3s, 0s;
            -webkit-transition-delay: 0.3s, 0s;
        }
        &:before {
            top: -1em;
            transition-property: top, transform;
            -webkit-transition-property: top, transform;
        }

        &:after {
            bottom: -1em;
            transition-property: bottom, transform;
            -webkit-transition-property: bottom, transform;
        }


    }
    &.active {
        span {
            background: none;
            &:before {
                top: 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
            &:after {
                bottom: 0;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
            &:before,
            &:after {
                transition-delay: 0s, 0.3s;
                -webkit-transition-delay: 0s, 0.3s;
            }
        }
    }
}

/* /Header*/

