//@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700');
.juster {
    display: inline-block;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.jfix-after:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.vfix {
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
}

.vfix-before:before {
    content: '';
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
}

.hidden-block {
    position: absolute;
    left: -99999em;
    top: -99999em;
}

* {
    box-sizing: border-box
}

h1, .h1, h2, .h2, h3, .h3, h4.h4, h5, .h5, h5, .h6 {
    margin-top: 15px;
    margin-bottom: 30px;
    font-weight: 400;
}

a:not(.btn) {
    color: #17a2b8;
    text-decoration: underline;
    &:hover {
        color: #1b1b1b;
        text-decoration: none;
    }
}

ol {
    counter-reset: item;
    li {
        display: block;
        position: relative;
        padding-left: 22px;
        &:before {
            content: counters(item, ".") " ";
            counter-increment: item;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            font-weight: bold;
        }
        ol {
            padding-left: 30px;
            li {
                padding-left: 30px;
                ol li {
                    padding-left: 45px;
                }
            }
        }
    }
}


/* Main */
.two-column {

    @extend .cfix;

    @media (min-width: 992px) {

        &__left {
            float: left;
            width: 262px;
        }
        &__right {
            margin-left: 292px;
        }
    }

    &--right {
        @media (min-width: 1200px) {

            .two-column__left {
                width: 749px;
            }
            .two-column__right {
                margin-left: 780px;
            }
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            .two-column__left {
                width: 569px;
            }
            .two-column__right {
                margin-left: 600px;
            }
        }
        @media (max-width: 991px) {
            .two-column__right {
                clear: both;
            }
        }
    }
}
/* /Main */

/* Навигация -------------------------------------------------*/
nav.nav {
    padding: 25px 25px 20px 45px;
    background-color: #009fd6;
    display: inline-block;
    float: left;
    position: fixed;
    z-index: 99;
    top: 60px;
    width: 24.9%;
    max-width: 320px;
}
.nav-menu {
    padding: 0;
}
.nav .nav-menu__object {
    margin-top: 0;
}
.nav .nav-menu__object,
.nav .nav-menu__buyers {
    font-size: 26px;
    font-family: "PTSans", sans-serif;
    font-weight: 700;
    color: #fff;
    position: relative;
    margin-bottom: 0;
}
.nav .nav-menu__object a,
.nav .nav-menu__buyers a {
    font-size: 26px;
    color: #fff;
    text-decoration: none;
}
.nav .nav-menu__object a:hover,
.nav .nav-menu__buyers a:hover {
    color: #001bbe;
    text-decoration: none;
}
.nav .nav-menu__object.active a,
.nav .nav-menu__buyers.active a {
    color: #001bbe;
    text-decoration: none;
}
.nav-menu li {
    list-style: none;
}
.nav-menu > li {
    margin-left: 20px;
    position: relative;
}
.nav-menu > li > a {
    font-size: 20px;
    font-family: "PTSans", sans-serif;
    font-weight: 400;
    color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    display: block;
    line-height: 30px;
    text-decoration: none;
}
.nav-menu > li > a:hover,
.nav-menu > li > a.active {
    font-family: "PTSans", sans-serif;
    font-weight: 700;
    font-size: 19px;
    color: #001bbe;
    text-decoration: none;
}
.nav-menu__sub-menu {
    margin-left: 20px;
    padding: 0;
}
.nav-menu__sub-menu > li > a {
    display: block;
    font-size: 17px;
    line-height: 25px;
    color: #fff;
    text-decoration: none;
}
.nav-menu__sub-menu li a:hover,
.nav-menu li > a.active {
    font-family: "PTSans", sans-serif;
    font-weight: 700;
    font-size: 17px;
    color: #001bbe;
    text-decoration: none;
}
.nav-menu li.parent::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background-color: #edeef0;
    left: -14px;
    top: 14px;
}
.nav .nav-menu__object::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 25px;
    background-image: url('../img/images/nav-menu__object.png');
    left: -25px;
    top: 2px;
}
.nav .nav-menu__buyers::after {
    content: '';
    position: absolute;
    width: 21px;
    height: 24px;
    background-image: url('../img/images/nav-menu__bayers.png');
    left: -26px;
    top: 2px;
}
.main {
    width: 100%;
    display: inline-block;
    margin-top: 60px;
}
.main-content {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding: 20px 40px 40px 360px;
}
.main__title {
    font-size: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #010101;
    text-align: center;
    border-bottom: 1px solid #cbcbcb;
    margin: 0;
    padding-bottom: 10px;
}
.main__content-row.row-1 {
    margin-top: 25px;
}
.main__content-row {
    margin-top: 32px;
}
.main__content-row h3 {
    font-size: 21px;
    font-family: "PTSans", sans-serif;
    font-weight: 700;
    color: #eb6f00;
    margin: 0 0 18px;
}
.main__content-row h4 {
    font-size: 18px;
    font-family: "PTSans", sans-serif;
    font-weight: 700;
    color: #ac2800;
    padding-left: 100px;
    margin-top: 0;
    margin-bottom: 15px;
}
.main__content {
    border: 1px solid #009fd6;
    background-color: #fbfeff;
    border-radius: 3px;
    padding: 25px 35px 25px;
    margin-top: 10px;
}
.main__content .form-control {
    display: inline-block;
}
.main__content-row.row-1 label.control-label-2  {
    padding: 0;
}
.main__content label span {
    display: inline-block;
    font-size: 20px;
    font-family: "PTSans", sans-serif;
    font-weight: 700;
    color: #272727;
    max-width: 190px;
    /*width: 100%;*/
}
.main-content .control-label {
    display: inline-block;
    font-size: 20px;
    color: #272727;
    margin-top: 0;
    vertical-align: top;
    font-family: "PTSans", sans-serif;
    font-weight: 400;
    min-width: 190px;
    width: 20%;
    line-height: 30px;
}
.main-content .main__content-item:first-child .control-label {
    margin-top: 0;
}
.main-content .main__content-item:last-child .control-label {
    margin-bottom: 0;
}
.main__content .main__content-item.last input[type="text"] {
    min-width: 100px;
}
.main__content .main__content-item.last .control-label {
    margin: 0;
}
.main__content-item.item-row .control-label {
    margin: 0;
}
.main__content input[type="text"]::after {
    content: '';
    position: absolute;
    width: 11px;
    height: 2px;
    color: #010000;
}

.checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;
}
.radio input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
}
.radio__text {
    position: relative;
    cursor: pointer;
    text-align: center;
    width: 28px;
    height: 26px;
    line-height: 27px;
    z-index: 2;
}
.radio__text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 26px;
    transition: .2s;
    z-index: -1;
}
.radio__text:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 26px;
    transition: .2s;
    z-index: -1;
}
.radio input:checked + .radio__text {
    color: #fff;
}
.radio input:checked + .radio__text:before {
    background: #009fd6;
    border-radius: 1px;
}
.radio input:checked + .radio__text:after {
}
.radio input:focus + .radio__text:before {
}
div#radioboxes label {
    display: inline-block;
    margin-top: 0;
}
.main__radioboxes-from span,
.main__radioboxes-to span {
    display: inline-block;
    width: 20px;
}
#checkboxes {
    display: inline-block;
    margin-left: 287px;
    margin-top: 15px;
}
#radioboxes {
    display: inline-block;
    margin-left: 20px;
}
.main__radioboxes {
    display: inline-block;
    vertical-align: top;
    margin-left: -45px;
}
.main__radioboxes .main__radioboxes-from .radio__text {
    display: inline-block;
    margin-bottom: 20px;
}
.main__radioboxes-from .radio__text {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 20px;
}
.main__content-item.item-Room {
    margin-top: 20px;
}
.main__content-item.item-Room .control-label {
    vertical-align: text-bottom;
    line-height:30px;
}
.main__content-item .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    background-color: #009fd6;
}
.main__content-item .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    background-color: #009fd6;
}
.main__content-item .checkbox__wrap .checkbox {
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
}
.main__content-item .checkbox__wrap .checkbox__text {
    position: relative;
    cursor: pointer;
    text-align: center;
    width: 105px;
    height: 26px;
    line-height: 26px;
    z-index: 2;
}
.main__content-item .checkbox__wrap .checkbox__text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 105px;
    height: 26px;
    transition: .2s;
    z-index: -1;
}
.main__content-item .checkbox__wrap .checkbox__text:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 105px;
    height: 26px;
    transition: .2s;
    z-index: -1;
}
.checkbox input:checked + .checkbox__text:before {
    background: #009fd6;
    border-radius: 1px;
}
.main__checkboxes .main__radioboxes-to .checkbox {
    margin-bottom: 5px;
}
hr {
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: #ccc;
    border: 0;
    height: 1px;
}
hr + .main__content-item.main__content-item-floor {
    margin-top: 30px;
    margin-bottom: 15px;
}
.main__content-item .bootstrap-select.hr-line::after {
    top: 13px;
    right: -29px;
}
.main__content-row.row-2 .main__content-item:last-child .control-label {
    margin-top: 0;
}
.main__content-row.row-2 .main__content-item:last-child .btn-group.bootstrap-select.form-control {
    margin-bottom: 0;
}
.main-content .bootstrap-select.hr-line {
    display: inline-block;
    margin-right: 40px;
}
.form-group {
    margin-bottom: 0;
}
.main__content-row.row-2 #objectType_3 .main__content-item:last-child .control-label {
    margin-top: 0;
    margin-bottom: 0;
}
.main__content-row.row-2 #objectType_4 .main__content-item:last-child .control-label {
    margin-top: 0;
    margin-bottom: 0;
}
#objectType_4 hr + .main__content-item.main__content-item-floor {
    margin-top: 30px;
    margin-bottom: 10px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
    font-size: 17px;
}

/* review number --------------------------------------------------------------------------------------*/
.main-content-review input[type="tel"],
.main-content-review input[type="email"],
.main-content-review input[type="password"],
.main-content-review input[type="button"] {
    border: 1px solid #009fd6;
    border-radius: 3px;
    height: 28px;
    padding: 0 10px 0 10px;
    overflow: hidden;
    width: 100%;
    /*	max-width: 270px;*/
    font-size: 17px;
    color: #4e4e4e;
    -webkit-box-shadow: none;
    box-shadow: none;
    max-width: 180px;
    display: inline-block;
}
.main-content-review input[type="email"],
.main-content-review input[type="password"] {
    margin-bottom: 15px;
}
.main-content.main-content-review input[type="submit"] {
    font-size: 18px;
    float: left;
}
.main-content.main-content-review label {
    display: block;
    line-height: 30px;
    margin-bottom: 5px;
}
.main-content .add-tel {
    font-size: 18px;
    color: #4e4e4e;
    text-decoration: underline;
    margin-left: 20px;
    border: none;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.main-content .add-tel:hover {
    text-decoration: none;
}
.add-number__content {
    margin-top: 20px;
    display: none;
}
/*.add-tel.active + .add-number__content {
	display: block;
	}*/
.main-content-review input[type="text"] {
    border: 1px solid #009fd6;
    border-radius: 3px;
    height: 28px;
    padding: 0 27px 0 10px;
    overflow: hidden;
    width: 100%;
    /*	max-width: 270px;*/
    font-size: 17px;
    color: #4e4e4e;
    -webkit-box-shadow: none;
    box-shadow: none;
    max-width: 180px;
    display: inline-block;
}
.client-phones {
    margin-top: 20px;
    margin-bottom: 25px;
}
.client-phone {
    display: inline-block;
    margin-right: 25px;
}
.add-agency__content {
    margin-top: 15px;
    display: none;
}
.main-content.main-content-add-client .add-agency__content label {
    display: block;
    width: auto;
    max-width: 255px;
}
.main-content.main-content-add-client .add-agency__content .agency {
    width: auto;
    max-width: 205px;
}
#checkbox-agency {
    display: none;
}
#addAgency {
    display: none;
}
.all-agency-box {
    display: inline-block;
    margin-bottom: 15px;
}
.all-agency-box #all-agency {
    background: url('../img/images/down.png') no-repeat right;
    border: 1px solid #009fd6;
    border-radius: 3px;
    height: 28px;
    padding: 0 30px;
    width: 100%;
    min-width: 205px;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-padding-start: 6px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}
#all-agency option {
    cursor: pointer;
}
.add-agency-box {
    display: none;
}
a.btn-add-agency-box {
    display: inline-block;
    margin-left: 35px;
    line-height: 28px;
    color: #333;
    cursor: pointer;
    user-select: none;
    text-decoration: underline;
}
a.btn-add-agency-box:hover {
    text-decoration: none;
}
/* authorization --------------------------------*/
.authorization .main-content input[type="password"] {
    margin-bottom: 0;
}
.authorization {
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: #009fd6;
}
.authorization .loginForm {
    position: fixed;
    top: 50%;
    left: 50%;
    text-align: left;
    max-width: 380px;
    max-height: 200px;
    margin: -100px 0 0 -190px;
    padding: 25px 30px;
    background-color: #ffffff;
}
.authorization .main-content label {
    display: inline-block;
    text-align: left;
    max-width: 100px;
}
.authorization .main-content input {
    height: 38px;
    line-height: 38px;
    width: 100%;
    max-width: none;
    padding: 5px 10px 0 40px;
    border: none;
    background-color: #e7e7e7;
    color: #6c6c6c;
}
.authorization .main-content input:focus,
.authorization .main-content input:active {
    border: none;
    outline: none;
}
.authorization .main-content input[name="login"] {
    border-bottom: 1px solid #bababc;
    padding-top: 0;
    padding-bottom: 5px;
    color: #6c6c6c;
}
.authorization .main-content .userLogin {
    position: relative;
}
.authorization .main-content .userLogin::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 20px;
    background-image: url('../img/images/login.png');
    left: 10px;
    top: 7px;
}
.authorization .main-content .userPassword {
    position: relative;
}
.authorization .main-content .userPassword::before {
    content: "";
    position: absolute;
    width: 14px;
    height: 20px;
    background-image: url('../img/images/password.png');
    left: 11px;
    top: 11px;
}

.loginForm-data {
    background-color: #e7e7e7;
    padding: 5px 10px;
}
.authorization .main-content input[type="submit"] {
    display: block;
    height: 33px;
    line-height: 33px;
    width: 135px;
    font-size: 22px;
    float: none;
    color: #fff;
    margin: 20px auto 0;
    padding: 0;
    background-color: #009fd6;
}

/* ADD OBJECT --------------------------------------------------------------------------------------*/
.main-content {

}
.main__add-object .main__container.row {
    margin-top: 15px;
}
.main__add-object dl {
    margin: 0;
}
dl dt {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}
dl dd {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #eb6f00;
    margin: 0;
    margin-top: 5px;
}
.objects th,
.objects td  {
    border: 1px solid #009fd6 !important;
    text-align: center;
    padding: 0;
    height: 35px;
    width: auto;
    vertical-align: middle!important;
}
.objects th {
    height: 35px;
    line-height: 35px;
    width: auto;
}
.objects td  {
    padding: 10px;
}
.objects h2 {
    font-size: 19px;
    color: #009fd6;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}
#printTableButton {
    display: none;
    font-size: 16px;
    line-height: 36px;
    font-weight: 700;
    color: white;
    background-color: #009fd6;
    text-align: center;
    float: right;
    border: none;
    padding: 0 15px;
}
#printTableButton:hover {
    background-color: #0085c3;
}
#printTableButton:focus {
    background-color: #0085c3;
}
.printTable {
    display: none;
    font-size: 16px;
    line-height: 36px;
    font-weight: 700;
    color: white;
    background-color: #009fd6;
    text-align: center;
    float: right;
    margin-bottom: 20px;
    border: none;
    padding: 0 15px;
}
.printTable:hover {
    background-color: #0085c3;
}
.main__add-object .col.main__content-row.row-1 {
    margin-top: 35px;
}
.main__content-item {
    margin-bottom: 25px;
}
.main__content-item:last-child {
    margin-bottom: 0;
}
.main-content .main__content textarea,
.main-content .main__content .form-group textarea {
    border: 1px solid #009fd6;
    border-radius: 3px;
    padding: 15px 10px;
    width: 79%;
    font-size: 18px;
    color: #252525;
    background-color: transparent;
    margin-right: 0;
    text-align: left;
}
.main__add-object .main__content-item.item-Room {
    margin: 0;
}
.main__add-object .main__content-item.item-Room .main__radioboxes {
    margin-top: 5px;
}
.main__add-object .main__content-item .idObject {
    display: inline-block;
    margin-top: 5px;

}
.main__add-object .main__content-item.item-Room .main__checkboxes {
    vertical-align: top;
}
.main__add-object .btn-group.bootstrap-select.form-control {
    margin-bottom: 0;
}
.main__add-object .main__content-row.row-2 .main__content-item:last-child .control-label {
    margin-top: 0;
}
.main-content.main__add-object .main__content-col {
    display: flex;
    textarea {
        margin-right: 0;
        width: 100%;
        text-align: left !important;
    }
}
.main-content .currencyPrice {
    display: none;
}
.main-content .units-price {
    display: inline-block;
}
.main-content .units-price {
    vertical-align: top;
    padding-right: 15px;
    min-width: 100px;
}
.main-content .units-price input {
    margin-right: 10px;
}
.main-content .currencyPrice .btn-group.bootstrap-select.form-control {
    min-width: 35px;
    max-width: 100%;
    margin-right: 25px;
    border: none;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.currencyPrice .btn-group.bootstrap-select.form-control {
    margin-bottom: 0;
}
.main-content .currencyPrice .dropdown-toggle {
    background: no-repeat;
    border: none;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 26px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}
/* change-price */
.change-price #unitsPrice{
    display: none;
}
.change-price .currencyPrice .btn-group.bootstrap-select.form-control{
    display: none;
}
.change-price .units-price {
    line-height: 30px;
}
.change-price .currencyPriceObj {
    line-height: 28px;
    font-size: 20px;
}
.form-group.col-md-6.left {
    width: 50%;
    flex: 1;
    margin-right: 40px;
}
.form-group.col-md-6.right {
    margin-left: 0;
    width: 50%;
    flex: 1;
}
.main-content.main__add-object .main__content-row.row-4 .main__content label {
    display: inline-block;
    margin: 0;
}
.main__add-object .main__content-row.row-4 .current {
    min-width: 103px;
    margin-right: 10px;
}
.main__add-object .main__content-row.row-4 img {
    vertical-align: top;
    padding-top: 3px;
    margin-right: 35px;
}
.main-content.main__add-object .main__content-row.row-3 textarea {
    margin-bottom: 35px;
}
.main__content-row.row-5 #checkbox-3 {
    width: 0;
    margin-bottom: 0;
}
.main-content.main__add-object .checkbox-rev {
    position: absolute;
    margin: 7px;
    z-index: 0;
}
.content-row {
    margin-top: 25px;
}
.profile__user-name {
    font-size: 30px;
    line-height: 42px;
    color: #010101;
    margin: 0;
    padding-left: 10px;
    border-bottom: 1px solid #cbcbcb;
}
.profile h3 {
    font-size: 20px;
    color: #001bbe;
    margin: 0;
    margin-bottom: 10px;
}
.profile b {
    display: inline-block;
    min-width: 145px;
    width: auto;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #000;
}
.profile span {
    display: inline-block;
    font-size: 16px;
    color: #000;
}
.profile__col {
    display: inline-block;
    vertical-align: top;
}
.profile__info {
    line-height: 30px;
}
.profile__info p {
    margin: 0;
}
.profile__info .info-title {
    display: inline-block;
    margin-right: 15px;
}
.profile__info .info-main {
    display: inline-block;
}
.profile .menu-title {
    display: block;
    height: 31px;
    width: 100%;
    line-height: 30px;
    background-color: #009fd6;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #fff;
    text-align: center;
}
.profile__use-menu {
    max-width: 185px;
    width: 25%;
    float: right;
    margin-right: 30px;
}
.use-menu li {
    list-style: none;
    margin: 0;
}
.use-menu li a {
    display: block;
    height: 31px;
    width: 100%;
    line-height: 30px;
    font-size: 15px;
    color: #000;
    padding: 0 15px;
    text-align: left;
}
.profile__use-menu li a {
    border: 1px solid #009fd6;
    border-top: none;
}

/* PROFILE-EDIT */
.profile-edit {

}
.profile-edit .menu-title {
    display: block;
    height: 31px;
    width: 100%;
    line-height: 30px;
    background-color: #eefbff;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #009fd6;
    text-align: center;
}
.profile-edit__use-menu {
    max-width: 185px;
    width: 100%;
    float: right;
    margin-right: 30px;
    background-color: #fbfeff;
}
.profile-edit__use-menu li {
    position: relative;
}
.profile-edit__use-menu li a {
    padding: 0 35px;
}
.profile-edit__use-menu li a.active {
    color: #009fd6;
}
.icon-login::before {
    content: '';
    position: absolute;
    height: 14px;
    width: 11px;
    left: 12px;
    top: 5px;
    background: url("../img/images/user.png") no-repeat;
}
.icon-edit-pass::before {
    content: '';
    position: absolute;
    height: 12px;
    width: 9px;
    left: 12px;
    top: 9px;
    background: url("../img/images/icon-pass.png") no-repeat;
}
.icon-out::before  {
    content: '';
    position: absolute;
    height: 12px;
    width: 13px;
    left: 12px;
    top: 8px;
    background: url("../img/images/login__out-grey.png") no-repeat;
}
.main-content input.profile-edit__input {
    display: inline-block;
    height: 22px;
    width: auto;
    max-width: 210px;
    font-size: 16px;
}
.main-content.profile-edit label {
    display: inline-block;
    min-width: 145px;
    width: auto;
    margin: 0;
    font-size: 16px;
}
.profile-edit .main__content-item {
    margin-bottom: 10px;
}
.foto-edit {
    text-align: center;
    line-height: 34px;
    font-size: 14px;
}
.main-content.profile-edit label[for="profile__pass"] {
    min-width: 167px;
}
.main-content input.profile__pass,
.main-content input.profile__repass {
    max-width: 180px;
    margin-bottom: 0;
}
/* сторінка пошуку */
.table__res {
    display: none;
}
#objects {
    display: none;
}
.objects-full {
    text-align: left;
}

/* View card */
.full {

}
.full .main__radioboxes {
    margin-left: 0;
}
.full #radioboxes {
    margin-left: 0;
}
.arhive-buy-price {
    display: none;
    margin-top: 20px;
}
.arhive-buy-price .units-price {
    vertical-align: top;
    padding-right: 15px;
    min-width: 115px;
    max-width: none;
}
.arhive-buy-price .units-price input {
    width: 100%;
    min-width: 100%;
    line-height: 28px;
    vertical-align: middle;
}
.arhive-buy-price .control-label {
    margin-bottom: 0!important;
}
.add-agency {
    display: none;
    margin-top: 20px;
}
.add-agency .add-agency__content {
    margin-top: 0;
    line-height: 28px;
    vertical-align: top;
    font-weight: 500;
    width: 50%;
}
.add-agency .checkbox-custom + label {
    color: #272727;
    font-size: 16px;
    line-height: 28px;
    display: inline-block;
    margin-right: 118px;
}
.add-agency .agency {
    color: #000;
    display: inline-block;
}
/*Calendar*/
.status-calendar {
    position: absolute;
}
.full h3 {
    color: #eb6f00;
}
.objects-full th,
.objects-full td {
    text-align: left;
    width: 50%;
}
.status-object {
    position: relative;
    margin-bottom: 0;
    .bootstrap-select {
        vertical-align: top;
        .dropdown-menu {
            width: auto;
            border-radius: 0;
            padding: 0;
            max-height: none !important;
            border: none;
            margin: 0;
            max-width: none;
            li {
                a {
                    font-size: 18px;
                    &.status-active {
                        background: #009166 none;
                        color: #fefefe;
                        &.active {
                            font-weight: 700;
                        }
                    }
                    &.status-no-active {
                        background: #dd4900 none;
                        color: #fefefe;
                        &.active {
                            font-weight: 700;
                        }
                    }
                    &.status-no-active-to {
                        background: #e5a200 none;
                        color: #fefefe;
                        &.active {
                            font-weight: 700;
                        }
                    }
                    &.status-archive {
                        background: #e5a200 none;
                        color: #fefefe;
                        &.active {
                            font-weight: 700;
                        }
                    }
                    &:hover {
                        background-color: #009fd6;
                    }
                }
            }
        }
        .dropdown-toggle {
            border-radius: 0;
            font-size: 20px;
            height: 30px;
            line-height: 30px;
            min-width: auto;
            box-shadow: none;
            transition: none;
            background: none;
            border: none;
            width: auto;
            z-index: -1;
            margin-top: 0;
            padding: 0;
            &[title="Продається"] {
                background: #009166 none;
                color: #fefefe;
            }
            &[title="Проданий"] {
                background: #dd4900 none;
                color: #fefefe;
            }
            &[title="Знятий з продажу до"] {
                background: #e5a200 none;
                color: #fefefe;
            }
            &[title="Знятий з продажу на довгий термін"] {
                background: #e5a200 none;
                color: #fefefe;
            }
            .filter-option-inner {
                line-height: 30px;
            }
        }
        .filter-option {
            position: unset;
            display: block;
            border: none;
            border-radius: 0;
            overflow: hidden;
            box-shadow: none;
            height: 30px;
            padding: 0 20px;
            background-image: none;
            color: #fefefe;
            text-align: center;
            line-height: 30px;
            font-family: PTSans,sans-serif;
            font-weight: 400;
            font-size: 20px;
        }
        &.form-control {
            margin-bottom: 0;
            margin-right: 30px;
        }
    }
    #statusNoActiveData {
        display: none;
        margin-right: 20px;
        width: auto;
        min-width: 115px;
        max-width: 145px;
        line-height: 28px;
        vertical-align: bottom;
    }
    .statusObject {
        display: inline-block;
        min-width: auto;
        line-height: 30px;
    }
    #statusObject{
        z-index: -1;
    }
    .control-label {
        max-width: 100px;
    }
    .status-btn-box {
        display: inline-block;
        float: right;
        .btn {
            margin-top: 0;
        }
        .status-active {
            background-color: #009166;
            font-size: 18px;
            text-align: center;
            &:focus,
            &:hover {
                background-color: #009166;
                opacity: 0.8;
            }
            .text {
                color: #fefefe;
            }
        }
        .status-no-active {
            font-size: 18px;
            text-align: center;
            &:focus,
            &:hover {
                opacity: 0.8;
            }
            .text {
                color: #fefefe;
            }
        }
        .status-archive {
            background-color: #e5a200;
            font-size: 18px;
            text-align: center;
            &:focus,
            &:hover {
                background-color: #e5a200;
                opacity: 0.8;
            }
            .text {
                color: #fefefe;
            }
        }
    }
    #status-field {
        position: absolute;
        top: 0;
        left: 104px;
        display: block;
        border: none;
        border-radius: 0;
        overflow: hidden;
        box-shadow: none;
        height: 30px;
        padding: 0 20px;
        background-image: none;
        color: #fefefe;
        text-align: center;
        line-height: 30px;
        font-family: PTSans,sans-serif;
        font-weight: 400;
        font-size: 20px;
    }
    .form-control {
        box-shadow: none;
    }
}


.full {
    .all-agency-box {
        display: inline-block;
        margin-left: 15px;
    }
    select#all-agency {
        z-index: -1;
    }
}
#main .full .add-agency-box label {
    max-width: none;
    display: inline;
    margin-right: 35px;
}
.full .add-agency .checkbox-custom + label {
    margin-right: 10px;
    line-height: 30px;
}
.full .add-agency .agency {
    min-width: 180px;
}
.full .add-agency .btn-group.bootstrap-select.form-control {
    width: auto;
    height: auto;
    max-width: none;
}
.full label.disadvantagesDescription-label {
    width: 100%;
    display: block;
}
.full #disadvantagesDescription {
    width: 100%
}
.full #advantagesDescription {
    width: 100%;
}
.full label.advantagesDescription-label {
    width: 100%;
    display: block;
}
span.info-field.totalDescription {
    padding-left: 20px;
}
span.info-field.realtorDescription {
    padding-left: 20px;
}
span.info-field.advantagesDescription {
    padding-left: 20px;
}
span.info-field.disadvantagesDescription {
    padding-left: 20px;
}
.main__radioboxes span.info-field {
    line-height: 28px;
    width: auto;
}
/* settings fields */
body .main .hidden-field {
    display: none;
}
#radioboxes.hidden-field {
    display: none;
}
.full input#unitsPrice {
    min-width: 95px;
}
span.info-field {
    line-height: 28px;
    display: inline-block;
    word-wrap: break-word;
}
.click-item {
    cursor: pointer;
    user-select: none;
}
.click-item:hover {
    background-color: #009fd6;
}

.checkDiv {
    width: 200px;
}

/*history*/

.tabs {
    margin: 0 0 30px;
}
.tabs__caption {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    margin-top: 20px;
}
.tabs__caption li:first-child {
    margin-left: 15px;
}
.tabs__caption:after {
    content: '';
    display: table;
    clear: both;
}
.tabs__caption li {
    position: relative;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    height: 30px;
    color: #009fd6;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
}
.tabs__caption li:not(.active) {
    cursor: pointer;
}
.tabs__caption .active {
    height: 35px;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
}
.tabs__caption .active:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 5px;
    background-color: #009fd6;
}
.tabs__content {
    display: none;
    margin-top: 40px;
}
.tabs__content.active {
    display: block;
}
label.control-label.status-label {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    float: left;
    width: 90px;
}
#status {
    background-color: #009166;
    padding: 10px 20px;
    display: inline-block;
}

#status-history-field {
    position: absolute;
    top: 0;
    left: 104px;
    display: block;
    border: none;
    border-radius: 0;
    overflow: hidden;
    font-size: 18px;
    box-shadow: none;
    height: 28px;
    padding: 0 15px;
    background-image: none;
    color: #fefefe;
    text-align: center;
    line-height: 28px;
}
.status-edit {
    color: #001bbe;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-decoration: underline;
    padding-left: 20px;
}
.main-history {
    width: 70%;
}
.history-object-info {
    width: 524px;
    color: #272727;
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
    display: inline-block;
}
.history-object-info-block {
    margin-bottom: 40px;
}
.plus-minus-object-info {
    color: #272727;
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
}
.control-label-edit {
    display: inline-block;
    max-width: 100px !important;
    line-height: 20px;
}
.history-button-edit {
    color: #ffffff;
    font-size: 23px;
    font-weight: bold;
    background-color: #009fd6;
    margin-top: 20px;
    float: right;
}
/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.tableHistory {
    width: 100%;
    margin-bottom: 100%;
    text-align: center;
    border: 1px solid #009fd6;
}
.tableHistory > thead > tr > th {
    border: 1px solid #009fd6;
    text-align: center;
}
.tableHistory tr, th, td {
    border: 1px solid #009fd6;
    padding: 10px;
}
#main > section > div > div > div > div.col-md-2 > div > img {
    width: 100%;
    height: 100%;
}
.pagination {
    margin-top: 0;
    float: right;
}
.pagination li a.active{
    z-index: 2;
    color: #fff;
    font-weight: bold;
    background-color: #0085c3;
    border-color: #0085c3;
}
.pagination li a.active:hover{
    color: #fff;
    background-color: #0085c3;
    border-color: #0085c3;
}
.pagination li a:hover{
    z-index: 2;
    color: #0b1722;
    background-color: #009fd6;
    border-color: #009fd6;
}
.pagination li a:focus{
    outline: none;
}
#main > section > div > div.tabs__content.active > section > div.body-content > div > dl:nth-child(1) {
    margin: 0;
}
