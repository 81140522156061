// Px to em convert
@function em($pixels, $context: $browser-context) {
	@return #{$pixels/$context}em;
}

// Placeholer mixin
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}
// Placeholer mixin
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}

html {
	height: 100%;
}

html, body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
}

body {
	border: 0;
	min-width: 320px;
	min-height: 100%;
	position: relative;
    font-family: "PTSans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    background: #fff;
    color: #000;
}

img {
	max-width: 100%;
	vertical-align: top;
}

* {
	outline: 0;
}

.container {
	max-width: 100%
}

.outerContainer {
	margin: 0 -15px;
}

.icon {
	vertical-align: middle;
}

.transform-center {
	transform: translate(-50%, -50%);
}

.inlineIcon-xs {
	display: none;
}
.cfix {
    &:after {
        content: '';
        display: block;
        height: 0;
        width: 100%;
        clear: both;
    }
}

.gray-bg {
    background: #f3f3f3;
    margin-bottom: 20px;
}
.pad-block {
    padding:10px 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
body input:focus:required:invalid,
body textarea:focus:required:invalid {

}
body input:required:valid,
body textarea:required:valid {

}
.clearfix::after {
    content: '';
    display: table;
    width: 100%;
    font-size: 0;
    clear: both;
}
.width-25 {
    //max-width: 320px;
    //width: 24.5%;
    display: inline-block;
}
.hidden {
    display: none;
}
.main-content .hr-line {
    position: relative;
    display: inline-block;
}
.main-content .hr-line::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 11px;
    background-color: #010000;
    right: 15px;
    top: -7px;
}
/* Checkbox-custom */
#checkbox.checkbox-custom {
    display: none;
}
#main .checkbox-custom {
    display: none;
    width: 15px;
    margin-left: 5px;
    margin-bottom: 0;
    z-index: -1;
}
.checkbox-custom + label {
    position: relative;
    padding: 0 0 0 40px;
    cursor: pointer;
    font-size: 18px;
    color: #4e4e4e;
}
.checkbox-custom + label::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 1px;
    width: 21px;
    height: 21px;
    background: transparent;
    box-shadow: none;
    border: 1px solid #009fd6;
    border-radius: 3px;
}
.checkbox-custom + label::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 3px;
    width: 18px;
    height: 14px;
    background: transparent;
}
.checkbox-custom:checked + label:after {
    content: '';
    background-image: url('../img/images/check.png');
}
.checkbox-custom--right + label {
    position: relative;
    padding: 0;
    cursor: pointer;
    font-size: 18px;
    color: #4e4e4e;
    display: inline-block;
    margin-bottom: 0;
    background-color: #fbfeff;
    z-index: 1;
}
.checkbox-custom--right + label::before {
    content: '';
    position: absolute;
    top: 0px;
    right: -35px;
    width: 23px;
    height: 21px;
    background: transparent;
    box-shadow: none;
    border: 1px solid #009fd6;
    border-radius: 3px;
}
.checkbox-custom--right + label::after {
    content: '';
    position: absolute;
    top: 5px;
    right: -32px;
    width: 18px;
    height: 14px;
    background: transparent;
}
.checkbox-custom--right:checked + label:after {
    content: '';
    background: url('../img/images/check.png') no-repeat;
}
/* Введення іншого значення */
span.selectToOther {
    display: none;
    vertical-align: top;
    margin-left: 15px;
    width: 100%;
    max-width: 200px;
}
span.selectToOther input[type="text"] {
    width: 100%;
    min-width: 100%;
    line-height: 28px;
    font-size: 15px;
    text-align: left;
}

/* styles for field */
.input-field {
    border: 1px solid #009fd6;
    border-radius: 3px;
    height: 28px;
    padding: 0 15px;
    font-size: 18px;
    color: #252525;
    text-align: center;
    background-color: transparent;
}
 input.input {
    border: 1px solid #009fd6;
    border-radius: 3px;
    height: 28px;
    padding: 0 10px;
    width: 100%;
    max-width: 110px;
    font-size: 18px;
    color: #252525;
    text-align: left;
    background-color: transparent;
}
.messages-edit.successfully {
    display: none;
    color: #048200;
    font-size: 16px;
    margin: 10px;
}
.messages-edit.error {
    display: none;
    color: #ff0000;
    font-size: 16px;
    margin: 10px;
}
a.log-out {
    display: block;
    background-color: inherit;
    color: black;
    padding: 12px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
a.log-out img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}
a.log-out:hover {
    background-color: #eefbff;
    text-decoration: none;
}
a.log-out:focus {
    background-color: #009fd6;
}
.main__container {
    margin-top: 20px;
}
.flex {
    display: flex;
}
input[type="number"] {
    margin-right: 40px;
}
