/*fonts*/

@mixin font-face($style-name, $file:$style-name, $weight:normal, $style:normal) {
	$filepath: "/fonts/" + $file;
	@font-face {
		font-family: quote($style-name);
		src: url($filepath + ".eot");
		src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
		url($filepath + ".woff") format('woff'),
		url($filepath + ".ttf")  format('truetype'),
		url($filepath + ".svg#" + $style-name + "") format('svg');
		font-weight: $weight;
		font-style: $style;
	}
}

//  example->    @include font-face('PFDinTextPro', 'PFDinTextPro-Bold', 700, italic);
@include font-face('PTSans', 'PTSansBold', 700, normal);
@include font-face('PTSans', 'PTSansRegular', 400, normal);
@include font-face('PTSans', 'PTSansItalic', 300, italic);
@include font-face('Roboto', 'Roboto-Bold', 700, normal);
@include font-face('Roboto', 'Roboto-Regular', 400, normal);
@include font-face('Roboto', 'Roboto-Medium', 500, normal);
@include font-face('Roboto', 'Roboto-Italic', 300, italic);
