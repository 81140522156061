
.btn {
	cursor: pointer;
    height: 30px;
    border: none;
    background-color: #009fd6;
    border-radius: 1px;
    color: #fff;
    font-size: 22px;
    padding: 0 15px;
    border-bottom: 2px solid #0059c7;
    margin-top: 20px;S
    &:hover,
    &:focus {
        background-color: #0085c3;
        border-color: #0085c3;
        text-decoration: none;
    }
    /* btn status */
    &.btn-status-box {
        float: right;
        span {
            display: block;
            font-size: 18px;
            padding-right: 8px;
            width: auto;
            text-align: center;
        }
    }
    &.btn-status {
        float: none;
        border: none;
        display: none;
    }
    &.btn-status-edit {
        background: none;
        border: none;
        font-size: 20px;
        color: #001bbe;
        text-decoration: underline;
        padding: 0;
        line-height: 30px;
        &:hover{
            text-decoration: none;
        }

        .btn-status-access {
            text-transform: uppercase;
        }
    }
    &.btn-search {
        float: right;
    }
}
/* btn change price on viewSale page */
.btn-price-box {
    float: right;
    .btn {
        margin-top: 0;
    }
    span {
        display: block;
        font-size: 18px;
        padding-right: 8px;
        width: auto;
        text-align: center;
    }
    .btn-price {
        float: none;
        display: none;
    }
    .btn-price-edit {
        background: 0 0;
        border: none;
        font-size: 20px;
        color: #001bbe;
        text-decoration: underline;
        padding: 0;
        line-height: 30px;
    }
}
.bootstrap-select {
    &.dropdown.form-control{
        width: auto!important;
    }
    select {
        padding: 0 10px;
        width: 100%;
        z-index: -1;
        option {
            font-size: 18px;
            color: #272727;
        }
    }
    .dropdown-toggle {
        background: url('../img/images/down.png') no-repeat right;
        border: 1px solid #009fd6;
        border-radius: 3px;
        height: 28px;
        padding: 0 27px 0 20px;
        overflow: hidden;
        width: 100%;
        font-size: 18px;
        line-height: 27px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-padding-start: 6px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-top: 0;
        color: #272727;
        .filter-option {
            position: relative;
            .filter-option-inner {
                font-family: "PTSans", sans-serif;
                font-weight: 400;
                line-height: 26px;
                padding-right: 0;
            }
        }
        &:before {
            content: '';
            display: initial;
        }
    }
    span.bs-caret {
        display: none;
    }
    .glyphicon-ok::before {
        content: "\f00c";
        font-family: FontAwesome, sans-serif;
        font-size: 15px !important;
        text-rendering: auto;
        position: absolute;
        left: 0;
        color: #390f05;
        top: 0;
    }
    .dropdown-menu {
        border: 1px solid #009fd6;
        border-radius: 2px;
        max-width: 305px;
        margin-top: 0;
        padding: 0;
        overflow-x: hidden;
        width: auto;
        li {
            a {
                padding: 3px 25px;
                font-size: 17px;
            }
        }
        min-width: auto;
    }
    select[name="floors"] {
        border: 1px solid #009fd6;
        border-radius: 3px;
        height: 28px;
        padding: 0 10px;
        width: 100%;
        max-width: 67px;
        font-size: 18px;
        color: #252525;
        text-align: center;
    }
}

.microdistrict {
    width: 100%;
    display: inline-block;
    max-width: 270px;
    .bootstrap-select {
        .dropdown-menu {
            width: 560px;
            border: 1px solid #009fd6;
            border-radius: 3px;
            max-width: none;
            max-height: none!important;
            padding: 5px 0;
            .inner {
                max-height: 275px!important;
            }
            ul.dropdown-menu {
                max-width: 100%;
                border: none;
                border-radius: 0;
            }
            .bs-searchbox input {
                border: 1px solid #009fd6;
                border-radius: 3px;
                height: 28px;
                padding: 0 10px;
                width: 100%;
                max-width: 100%;
                font-size: 18px;
                color: #252525;
                text-align: left;
            }
            .bs-actionsbox {
                position: relative;
                padding: 0 15px;
                margin: 0 10px;
                border-bottom: 1px solid #cbcbcb;
                width: auto;
                .actions-btn.bs-select-all.btn.btn-default,
                .actions-btn.bs-deselect-all.btn.btn-default {
                    background-color: #009fd6;
                    border: none;
                    height: 29px;
                    max-width: 220px;
                    border-radius: 1px;
                    padding: 0;
                    font-size: 17px;
                    color: #fff;
                    margin-top: 0;
                }
                .actions-btn.bs-select-all.btn.btn-default:hover,
                .actions-btn.bs-deselect-all.btn.btn-default:hover {
                    background-color: #0085c3;
                    border-color: #0085c3;
                }
                .actions-btn.bs-select-all.btn.btn-default::after {
                    content: '';
                    position: absolute;
                    background-color: #cbcbcb;
                    height: 100%;
                    width: 1px;
                    left: 50%;
                    top: 0;
                }
                .actions-btn.bs-deselect-all.btn.btn-default {
                    float: right;
                }
            }
            .btn-group {
                padding: 10px 0;
            }
        }
    }
}
.btns-change {
    float: right;
    .submitForm {
        display: none;
    }
}
